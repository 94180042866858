import './styles/Typewriter.css';


function Typewriter() {

    return (
        <header className="typewriter-header">
            <div className="typewriter">
                <h1>Hey, I'm Dan</h1>
            </div>

            <div className="typewriterSmall">
                <h2 class>Welcome to my portfolio</h2>
            </div>
        </header>
    );
}

export default Typewriter