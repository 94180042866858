// https://react-slick.neostack.com/docs/get-started/
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

var React = require('react');

//TODO: find better way of passing refs, not this disgusting structure
function Carousel(props){

  const sliderRef = props.refs.sliderRef;
  const initialSlide = props.initialSlide;

  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "10px",
    slidesToShow: 1,
    initialSlide: initialSlide,
    afterChange: props.afterChange,
    speed: 500
  };

  const handleSliderMounted = (slider) => {
    sliderRef.current = slider;
  };

  return (
    <div className="middleContainer">
      <div id="flagCarousel">
        <Slider ref={handleSliderMounted} {...settings}>
          <div>
            <h3><img className="paddedcarouselimg" alt="uk flag" src={require('./assets/ukflag.png')} /> <p>1996 to 2017</p></h3>
          </div>
          <div>
            <h3><img className="paddedcarouselimg" alt= "norway flag" src={require('./assets/norwayflag.png')}  /> <p>2017 to 2021</p></h3>
          </div>
          <div>
            <h3><img className="paddedcarouselimg" alt="usa flag" src={require('./assets/usflag.png')}  /><p>2021 to 2023</p></h3>
          </div>
        </Slider>
      </div>
    </div>
  );
}

export default Carousel;
// Don't forget to include the css in your page 
// Begin DemoSliderControls