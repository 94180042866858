import React, { useEffect } from 'react';
import './styles/ResumeSectionDesktop.css';
import './styles/ResumeSectionMobile.css';
import { animateResumeEntries } from './AnimateResumeEntries.js';

function NorwayResume() {

 //TODO: WARNING, this side by side image approach WILL NOT WORK ON MOBILE, you will need to put image above text. that is why everything gets pushed off page
 //MARGINS TOO

    const isMobile = /iPhone|iPad|iPod|Android/i.test(window.navigator.userAgent);

    useEffect(() => {
        //Call on render as we may have changed flags and some entries already be visible
        const resumeEntries = document.querySelectorAll("div.resumeentry");
        animateResumeEntries(resumeEntries);
    
        document.addEventListener('scroll', () => {animateResumeEntries(resumeEntries)});
        return () => {
            document.removeEventListener('scroll', () => {animateResumeEntries(resumeEntries)});
        };
    }, []);
        
  return (
    <div>
    <div class="resumeentry animateddiv">
        <div className="resumeentryimagecontainer">
            <img class="resumeentryimage" src={require('./assets/microsoftlogo.png')}/>
        </div>
        
        <div className="resumetextcontainer">

            
        {isMobile ? (
                <div class="resumeentrytitle">
                    <h1>People Intelligence <br/> Software Engineer II</h1>
                </div>
            ) : (
                <div class="resumetextheader">
                    <div class="resumeentrytitle">
                        <h1>Software Engineer II | People Intelligence</h1>
                    </div>
                    <div class="resumeentryimagetypecontainer"><img class="resumeentryimagetypeemoji" src={require('./assets/work.png')}/></div>
                </div>
            )}

            <div class="resumeentrydate">
                <p>September 2019 - May 2020</p>
            </div>
            <div class="resumeentrytext">
                    <p> Formed on account of winning Microsoft's Annual Hackathon. The People Intelligence team was my first foray into creating an entirely new production grade product with a greenfield team.
                        Above all, this team taught me the prime importance of who you work with, rather than what it is you work on. Together, my small team and I 
                        prototyped several AI experiences embedded within Microsoft's "Office" ecosystem. 
                    </p>
                <ul>
                    <li>A founding member of the team and responsible for ensuring deadlines were met and team cohesion upheld. 
                    </li>
                    <li>My Hackathon project proposal “remote collaboration: working hours detection" was funded as a feature team to develop a production grade product.
                    </li>
                    <li>Performed full-stack development from modelling and architecture to schema and API design across both Azure and Substrate.
                    </li>	
                    <li>Drove the decision-making process of many early team deliverables through prototyping and development.
                    </li>
                    <li>Developed team internal visualisation tooling which was subsequently adopted across the company.
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <div class="resumeentry animateddiv">
        <div className="resumeentryimagecontainer">
            <img class="resumeentryimage" src={require('./assets/redcross.png')}/>
        </div>
        
        <div className="resumetextcontainer">
            
        {isMobile ? (
                <div class="resumeentrytitle">
                    <h1><a href="https://www.cbsrc.org/">Community-Based Surveillance</a> <br/> Data Processing</h1>
                </div>
            ) : (
                <div class="resumetextheader">                
                    <div class="resumeentrytitle">
                        <h1>Community-Based Surveillance | Data Processing | <a href="https://www.cbsrc.org/">Red Cross Project</a> </h1>
                    </div>
                    <div class="resumeentryimagetypecontainer"><img class="resumeentryimagetypeemoji" src={require('./assets/project.png')}/></div>
                </div>
            )}

            <div class="resumeprojectwinner">
                <div class="resumeemoji">🥇 Winner - Microsoft Spring FixHackLearn 2019 🥇</div>
            </div>
            <div class="resumeentrytext">
                    <p> In collaboration with Norwegian Red Cross, this project aimed to reduce the manual labour and delay time involved in 
                        collating, predicting, and responding to outbreaks of infectious diseases in third world countries.
                    </p>
                <ul>
                    <li> The existing outbreak case-detection system involved a man on a bicycle travelling to different villages with pen and paper.
                    </li>
                    <li>We combined cheap pre-paid SMS phones given to every village with a mobile celluar beacon
                        and an simple messaging reply system to significantly reduce the labour cost and time for case detection.
                    </li>
                    <li>
                        By allowing the communities to report on health situations the Red Cross was able to ensure the right humanitarian aid was rapidly provided,
                        in the right places at the right time.
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <div class="resumeentry animateddiv">
        <div className="resumeentryimagecontainer">
            <img class="resumeentryimage" src={require('./assets/workinghours.png')}/>
        </div>
        
        <div className="resumetextcontainer">


        {isMobile ? (
                <div class="resumeentrytitle">
                    <h1>Remote Collaboration <br/> Active-Working Hours</h1>
                </div>
            ) : (
                <div class="resumetextheader">
                    <div class="resumeentrytitle">
                        <h1>Remote Collaboration: Active-Working Hours</h1>
                    </div>
                    <div class="resumeentryimagetypecontainer"><img class="resumeentryimagetypeemoji" src={require('./assets/project.png')}/></div>
                </div>
            )}

            <div class="resumeprojectwinner">
                <div class="resumeemoji">🥇 Winner - Microsoft E+D Annual Hackathon 2019 🥇</div>
            </div>
            <div class="resumeentrytext">
                    <p> To promote more effective remote work, this project developed an auto-detection for people’s most active working time 
                        segment utilising telemetry from several Microsoft products. This project was funded by senior leadership and I became a founding member
                        of a new feature team.
                    </p>
            </div>
        </div>
    </div>

    <div class="resumeentry animateddiv">
        <div className="resumeentryimagecontainer">
            <img class="resumeentryimage" src={require('./assets/microsoftlogo.png')}/>
        </div>
        
        <div className="resumetextcontainer">

        {isMobile ? (
                <div class="resumeentrytitle">
                    <h1>People Infrastructure <br/> Software Engineer</h1>
                </div>
            ) : (
                <div class="resumetextheader">
                    <div class="resumeentrytitle">
                        <h1>Software Engineer | People Infrastructure</h1>
                    </div>
                    <div class="resumeentryimagetypecontainer"><img class="resumeentryimagetypeemoji" src={require('./assets/work.png')}/></div>
                </div>
            )}

            <div class="resumeentrydate">
                <p>September 2017 - August 2019 <br/>
                    Over 1,000,000,000 Users Served!
                </p>
            </div>
            <div class="resumeentrytext">
                    <p> My first role at Microsoft involved building and maintaining a highly performant and highly available REST API to serving Profile and Image data,
                        supporting the vast majority of Microsoft internal products. Here I focused on 
                    </p>
                <ul>
                    <li>Developed and managed a REST API serving profile and image data to over 1.1 billion monthly active users with 
                        215,000rps and distributed across over 100,000 servers.
                    </li>
                    <li> Built a GDPR-compliant monitoring infrastructure to assist the profile and image service. 
                    </li>
                    <li> Architected and implemented a data analytics pipeline to provide insights on the customer usage of the REST API, 
                        processing up to 200 TB of data daily.
                    </li>	
                </ul>
            </div>
        </div>
    </div>
    </div>
  );
}

export default NorwayResume;