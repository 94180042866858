import './styles/ImageGrid.css';

function ImageGrid(props) {

  //TODO: I think if you want to change slider stuff here, you need a ref to it. and I think you're only gonne get that if it's returned from the slider
  //and also passed into here

  const sliderRef = props.refs.sliderRef;

  function animateCarouselImages(imageNumber) {
    sliderRef.current.slickGoTo(imageNumber);
  }

  return (
    <div className="images">
    
        <div className="paddedimg">
          <img id="ukflagsmall.png" src={require('./assets/ukflagsmall.png')} onClick={() => animateCarouselImages(0)}alt="Snow"/>
        </div>

        <div className="paddedimg">
          <img id="norwaysmallimg" src={require('./assets/norwayflagsmall.png')} onClick={() => animateCarouselImages(1)} alt="Forest"/>
        </div>
        <div className="paddedimg">
          <img id="ussmallimg" src={require('./assets/usflagsmall.png')} onClick={() => animateCarouselImages(2)} alt="Mountains"/>
        </div>
    </div>
  );
}

export default ImageGrid;
