import React from 'react';
import JourneySectionIntro from './JourneySectionIntro';
import JourneySectionCarousel from './JourneySectionCarousel';


function JourneySection() {
  
    return (
        <section>
            <JourneySectionIntro/>
            <JourneySectionCarousel/>
        </section>
    );
  }

  export default JourneySection;