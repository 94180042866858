export function animateResumeEntries(resumeEntries) {
    // Convert NodeList to Array
    const resumeEntriesArray = Array.from(resumeEntries);

    //first one isn't animated.

    // full div must be displayed. make it so if the bottom just lower than the div then do it

    // Sort the array of divs by their vertical position on the page
    resumeEntriesArray.sort(function(a, b) {
        const aRect = a.getBoundingClientRect();
        const bRect = b.getBoundingClientRect();
        return aRect.top - bRect.top;
    });


    for (let i = 0; i < resumeEntriesArray.length - 1; i++) {
        let entry = resumeEntriesArray[i];
        let entryBoundingBox = entry.getBoundingClientRect();
        let nextEntry = resumeEntriesArray[i+1];

        //case, first div
        if (entryBoundingBox.top <= window.innerHeight){
            entry.classList.add("active");
            entry.classList.remove("hidden");
        }
        
        if (entryBoundingBox.bottom + 100 <= window.innerHeight) {
            nextEntry.classList.add("active");
            nextEntry.classList.remove("hidden");
        }
      }
}