import React, { useEffect } from 'react';
import './styles/ResumeSectionDesktop.css';
import './styles/ResumeSectionMobile.css';
import { animateResumeEntries } from './AnimateResumeEntries.js';


function UnitedStatesResume() {
 //TODO: BETTER LINKS ON THE PAPER

  const isMobile = /iPhone|iPad|iPod|Android/i.test(window.navigator.userAgent);
  useEffect(() => {
    //Call on render as we may have changed flags and some entries already be visible
    const resumeEntries = document.querySelectorAll("div.resumeentry");
    animateResumeEntries(resumeEntries);
  
    document.addEventListener('scroll', () => {animateResumeEntries(resumeEntries)});
    return () => {
        document.removeEventListener('scroll', () => {animateResumeEntries(resumeEntries)});
    };
    }, []);
    

  return (
    <div>
    <div class="resumeentry animateddiv">
        <div className="resumeentryimagecontainer">
            <img class="resumeentryimage" src={require('./assets/microsoftxresearch.png')}/>
        </div>
        
        <div className="resumetextcontainer">      
            {isMobile ? (
                <div class="resumeentrytitle">
                    <h1>Program Synthesis <br/> Research & Engineering <br/>Software Engineer II</h1>
                </div>
            ) : (
                <div class="resumetextheader">
                        <div class="resumeentrytitle">
                            <h1>Software Engineer II | Program Synthesis Research & Engineering</h1>
                        </div>
                        <div class="resumeentryimagetypecontainer"><img class="resumeentryimagetypeemoji" src={require('./assets/work.png')}/></div>
                </div>
            )}

            <div class="resumeentrydate">
                <p>May 2020 - Current</p>            
            </div>
            
            <div class="resumeentrytext">
                <p>My work on the PROSE team represents the culimation of my dream to advance the state of the art 
                    in artificial intelligence. Throughout the years I have been exposed to the intricacies of the research methodology
                    alongside cutting edge artificial intelligence techniques, and have worked alongside some of the best researchers in the world.
                </p>
                <ul>
                    <li>Selected to assess the capacity and ability of OpenAI’s GPT-3, Codex and ChatGPT. Developed benchmarks and 
                        evaluation techniques, appropriate domains of application and future areas of research.
                    </li>
                    <li>Prototyped several early-stage LLM-based developer tools in VsCode with a reciprocal research relationship with 
                        GitHub Copilot.</li>
                    <li>Created and deployed a VsCode extension to extract key developer information to power a “repeated-edits” 
                        framework for code repair, which was deployed in universities across three countries. </li>
                    <li>Built a containerised web application named “AITutor” to combine code repair technology, execution 
                        infrastructure, and real-time hints to scaffold learning in University of Michigan's Online Data Science Class. <a href="https://www.coursera.org/learn/python-data-analysis">[1]</a></li>
                    <li>Created three new Data Extraction experiences utilising the Program Synthesis SDK into Microsoft 
                        PowerQuery Desktop (.NET) and Web (ReactJs). These experiences are utilised by approximately 100,000 users 
                        daily.&nbsp;
                        <a href="https://learn.microsoft.com/en-us/power-query/connectors/web/web-by-example">[2]</a>
                        <a href="https://support.microsoft.com/en-us/office/add-a-column-from-an-example-power-query-cd82c570-8da7-4d70-91a1-3827b5995eab">[3]</a>
                        <a href="https://learn.microsoft.com/en-us/power-query/connectors/text-csv#textcsv-by-example">[4]</a></li>
                </ul>
            </div>
        </div>
    </div>

    <div class="resumeentry animateddiv">
        <div className="resumeentryimagecontainer">
            <img class="resumeentryimage" src={require('./assets/covidwa1.png')}/>
        </div>

        <div className="resumetextcontainer">

            {isMobile ? (
                <div class="resumeentrytitle">
                    <h1><a href="https://www.covidwa.com/news/">CovidWA.com</a> <br/>Co-Founder</h1>
                </div>
            ) : (
                <div class="resumetextheader">
                    <div class="resumeentrytitle">
                        <h1>Co-Founder | Scraper Tech | <a href="https://www.covidwa.com/news/">Covidwa.com News & Testimonials</a></h1>
                    </div>
                    <div class="resumeentryimagetypecontainer"><img class="resumeentryimagetypeemoji" src={require('./assets/philanthropy.png')}/></div>
                </div>
            )}


        <div class="resumeprojectwinner">
            <div class="resumeemoji">🥇 Winner - GeekWire "Geeks Who Give Back" Award 2022 🥇</div>
        </div>

        <div class="resumeentrytext">
                <p>The COVIDWA.com project began 7 days after I'd moved to the United States to help people find appointments for COVID-19 vaccines. The senior citizens of Washington had been thrown into a free-for-all, 
                expected to search thousands of websites individually for rapidly-closing appointments. We<b> automated </b>this process helping over 1,000,000 people directly and an additional 2,500,000 through our API.
                A few learnings from this project are called out below:
                </p>
                <ul>
                    <li> There are limits to how fast you can scale an organisation. Eventually, an extra person leads to more overhead than impact.
                    </li>
                    <li> Despite your intentions being good, your success can contrast the failure of powerful people. The CovidWa project was built by volunteers, and scaled to support millions
                        in weeks. Powerful companies had been paid $40+ million dollars with year timeframes and failed to deliver. 
                    </li>
                    <li> 
                         The media loves a feel-good story.
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <div class="resumeentry animateddiv">
        <div className="resumeentryimagecontainer">
            <img class="resumeentryimage" src={require('./assets/aitutor.png')}/>
        </div>

        <div className="resumetextcontainer">

        {isMobile ? (
                    <div class="resumeentrytitle">
                        <h1>AITutor <br/> Tech Lead <br/> <a href="https://www.microsoft.com/en-us/research/project/ai-for-programming-education/">Microsoft Research</a></h1>
                    </div>
            ) : (
                <div class="resumetextheader">
                    <div class="resumeentrytitle">
                        <h1>AITutor | Tech Lead | <a href="https://www.microsoft.com/en-us/research/project/ai-for-programming-education/">Microsoft Research</a></h1>
                    </div>
                    <div class="resumeentryimagetypecontainer"><img class="resumeentryimagetypeemoji" src={require('./assets/project.png')}/></div>
                </div>
        )}

        <div class="resumeprojectwinner">
            <div class="resumeemoji">🥇 Winner - Microsoft CTO Codex Challenge 2022 🥇</div>
        </div>
        <div class="resumeentrytext">
                <p>The AI-Tutor project combined the power of modern Large-Language Models and Program Synthesis technology with verification via assignment unit-tests. 
                    Using a combination of code repair providers, the project repairs the student's code, finds an explaination for the repair, and then turns that explaination
                    into a "hint" rather than a direct guideline in line with supporting pedagogy. 
                </p>
                <ul>
                    <li> Built using Containerised infrastructure, able to verify 100's of candidate fixes and return in seconds
                    </li>
                    <li> Easily integrated into VsCode and Juypter via simple client side API's
                    </li>
                    <li> Deployed in real-world courses in a number of top colleges in the United States
                    </li>
                </ul>
            </div>
        </div>
    </div>


    <div class="resumeentry animateddiv">
        <div className="resumeentryimagecontainer">
            <img class="resumeentryimage" src={require('./assets/fixie.png')}/>
        </div>

        <div className="resumetextcontainer">

        {isMobile ? (
                    <div class="resumeentrytitle">
                        <h1>Email Assistant | Team Lead | <a href="https://twitter.com/juberti/status/1634735034296070145?s=20">Fixie Hackathon</a></h1>
                    </div>
            ) : (
                <div class="resumetextheader">
                    <div class="resumeentrytitle">
                        <h1>Email Assistant | Team Lead | <a href="https://twitter.com/juberti/status/1634735034296070145?s=20">Fixie Hackathon</a></h1>
                    </div>
                    <div class="resumeentryimagetypecontainer"><img class="resumeentryimagetypeemoji" src={require('./assets/project.png')}/></div>
                </div>
        )}

        <div class="resumeprojectwinner">
            <div class="resumeemoji">🥇 Winner - Fixie Inaugural Hackathon 2023 🥇</div>
        </div>
        <div class="resumeentrytext">
                <p>In an effort to reduce the load that emails put on a C-Suite employee, the email assistant reads and creates draft replies for all incoming emails for a GMail account. 
                    The user can then accept, edit, or decline to reply with any of the drafts. The user can also add configuration parameters such as "I'm very busy today" which will modify
                    the draft emails accordingly.
                </p>
            </div>
        </div>
    </div>
    

    <div class="resumeentry animateddiv">
        <div className="resumeentryimagecontainer">
            <img class="resumeentryimage" src={require('./assets/research.png')}/>
        </div>
        
        <div className="resumetextcontainer">

        {isMobile ? (
                <div class="resumeentrytitle">
                    <h1>Multi-modal program inference: a marriage of pre-trained language models and component-based synthesis</h1>      
                </div>
            ) : (
                <div class="resumetextheader">                
                    <div class="resumeentrytitle">
                        <h1>Multi-modal program inference: a marriage of pre-trained language models and component-based synthesis</h1>      
                    </div>
                    <div class="resumeentryimagetypecontainer"><img class="resumeentryimagetypeemoji" src={require('./assets/researchpaper.png')}/></div>
                </div>
        )}

            <div class="resumeprojectwinner">
                📖 <a href="https://dl.acm.org/doi/abs/10.1145/3485535?sid=SCITRUS">OOPSLA 2021</a> 📖
            </div>
            <div class="resumeentrytext">
                    <p> Multi-modal program synthesis refers to the task of synthesizing programs (code) from their specification given in different forms,
                        such as a combination of natural language and examples.
                        Examples provide a precise but incomplete specification, and natural language provides an ambiguous but more "complete" task description.
                    </p>
            </div>
        </div>
    </div>
    </div>
  );
}

export default UnitedStatesResume;