import React, { useEffect } from 'react';
import './styles/ResumeSectionDesktop.css';
import './styles/ResumeSectionMobile.css';
import { animateResumeEntries } from './AnimateResumeEntries.js';

function UnitedKingdomResume() {

 //TODO: WARNING, this side by side image approach WILL NOT WORK ON MOBILE, you will need to put image above text. that is why everything gets pushed off page
 //MARGINS TOO

 //TODO: make all the headers fit on one page

    const isMobile = /iPhone|iPad|iPod|Android/i.test(window.navigator.userAgent);

    useEffect(() => {
        //Call on render as we may have changed flags and some entries already be visible
        const resumeEntries = document.querySelectorAll("div.resumeentry");
        animateResumeEntries(resumeEntries);
    
        document.addEventListener('scroll', () => {animateResumeEntries(resumeEntries)});
        return () => {
            document.removeEventListener('scroll', () => {animateResumeEntries(resumeEntries)});
        };
    }, []);
    

  return (
    <div>
    <div class="resumeentry animateddiv">
        <div className="resumeentryimagecontainer">
            <img class="resumeentryimage" src={require('./assets/universityofsurreyoriginal.png')}/>
        </div>
        
        <div className="resumetextcontainer">

            {isMobile ? (
                <div class="resumeentrytitle">
                    <h1>BSc Computer Science <br/>Valedictorian, Class of 2017</h1>
                </div>
            ) : (
                <div class="resumetextheader">
                    <div class="resumeentrytitle">
                        <h1>BSc Computer Science | Valedictorian, Class of 2017</h1>
                    </div>
                    <div class="resumeentryimagetypecontainer"><img class="resumeentryimagetypeemoji" src={require('./assets/education.png')}/></div>
                </div>
            )}
     
            <div class="resumeentrydate">
                <p>September 2014 - September 2017</p>
            </div>
            <div class="resumeentrytext">
                <p>
                    Where it all began. My final year thesis focused on the intersection of Neural and Bayesian networks applied to the domain of Road Safety.
                    Combining these models into a production application, I built the infrastructure to plot the safest routes between destinations.
                </p>
                <div class="resumeprojectwinner">
                    <div class="resumeemoji">🥇 Winner - 4 Academic Awards 🥇</div>
                </div>
                <ul>
                    <li>Department of Computing Prize for Best Overall Performance</li>
                    <li>IBM Prize for Best Final Year Performance</li>
                    <li>Department for Energy and Climate Change Prize</li>
                    <li>Best Final Year Project </li>
                </ul>
            </div>
        </div>
    </div>

    <div class="resumeentry animateddiv">
        <div className="resumeentryimagecontainer">
            <img class="resumeentryimage" src={require('./assets/ContextualShuffle.png')}/>
        </div>
        
        <div className="resumetextcontainer">

            {isMobile ? (
                <div class="resumeentrytitle">
                    <h1><a href="https://www.devpost.com/software/contextualshuffle">ContextualShuffle </a> <br/> Tech Lead </h1>
                </div>
                ) : (
                <div class="resumetextheader">
                    <div class="resumeentrytitle">
                        <h1>ContextualShuffle | Tech Lead | <a href="https://www.devpost.com/software/contextualshuffle">Devpost</a></h1>
                    </div>
                    <div class="resumeentryimagetypecontainer"><img class="resumeentryimagetypeemoji" src={require('./assets/project.png')}/></div>
                </div>
            )}
     
            <div class="resumeprojectwinner">
                <div class="resumeemoji">🥇 Winner - ICHACK 2017 (largest student hack in the UK) 🥇</div>
            </div>
            <div class="resumeentrytext">
                    <p> Generates a “shuffle” of songs based on the current context of songs the user is listening to and utilises decision trees
                        to queue similar songs in the playlist to reduce the variation between tracks, e.g. hiphop would not follow classical.
                    </p>
            </div>
        </div>
    </div>

    <div class="resumeentry animateddiv">
        <div className="resumeentryimagecontainer">
            <img class="resumeentryimage" src={require('./assets/research.png')}/>
        </div>
        
        <div className="resumetextcontainer">

            {isMobile ? (
                <div class="resumeentrytitle">
                    <h1>On Making Sense of Neural Networks in Road Analysis</h1>
                </div>
                ) : (
                <div class="resumetextheader">
                    <div class="resumeentrytitle">
                        <h1>On Making Sense of Neural Networks in Road Analysis</h1>
                    </div>
                    <div class="resumeentryimagetypecontainer"><img class="resumeentryimagetypeemoji" src={require('./assets/researchpaper.png')}/></div>
                </div>
            )}

            <div class="resumeprojectwinner">
                <div class="resumeemoji"> 🥇 Winner - The Sean Morley Memorial Award (UK Government Grant) 🥇</div>
                <div class="resumeemoji">📖  <a href="https://ieeexplore.ieee.org/document/7966415">IJCNN 2017</a> 📖</div>
            </div>
            <div class="resumeentrytext">
                    <p> Proposed a new Bayesian-neural network hybrid machine learning technique which combines the transparency and ease of 
                        computation of Bayesian networks with the advanced learning methodologies of neural networks. This hybrid model can predict 
                        road accidents with 85% accuracy.
                    </p>
            </div>
        </div>
    </div>

    </div>
  );
}

export default UnitedKingdomResume;