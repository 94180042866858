import React, { useEffect } from 'react';
import './styles/AnimatedDiv.css';

function JourneySectionIntro() {

    const isMobile = /iPhone|iPad|iPod|Android/i.test(window.navigator.userAgent);

    useEffect(() => {
        const textBox = document.querySelector('#journeysectionintrotext.animateddiv');


        function animateTextBox() {
            const textBoxPosition = textBox.getBoundingClientRect();
    
            // Check if the textbox is in view
            // Want it to appear when it's partially in view, so add a bit to what the window can see

            let viewActivationOffset = 0;

            if (isMobile){
                viewActivationOffset = 125;
            }

            if (textBoxPosition.top >= 0 && textBoxPosition.bottom <= window.innerHeight + viewActivationOffset) {
                textBox.classList.add('active');
                textBox.classList.remove('hidden');
            } 
      }
  
      document.addEventListener('scroll', animateTextBox);
      return () => {
        document.removeEventListener('scroll', animateTextBox);
      };
    }, []);

    return (
        <div>
            <div className="middleContainer">
                <div className="line"></div>
                <h1 id="myjourney" className="defaultlargeWithBanner">My Journey</h1>
                <div className="line"></div>
            </div>
                
            <div id="journeysectionintrotext" className="animateddiv">
                <div className="middleContainerLarge defaulttext">
                    <p className="defaulttext" align="justify">I've called three countries home in the last 6 years.
                    Each represents a different chapter of my life. Every chapter has its own experiences, projects and learnings.
                    You can view each of these chapters below.
                    </p>
                        
                {isMobile ? (
                    <h3 align="center">
                    Swipe to scroll
                    </h3>
                ) : (
                    <h3 align="center">
                    Change flag to scroll
                    </h3>
                )}

                </div>
            </div>
        </div>
    );
}

export default JourneySectionIntro;