import './styles/ContactsSection.css';

function ContactsSection() {
    return (
    <section id="contactssection">
        <div id="headercontainercontacts">
            <h1 id="myprofiles" className="defaultlarge">My Profiles</h1>
            <a href={require('./assets/Daniel Morris CV 2023N26-WEBFORK.pdf')} download="Daniel Morris CV.pdf">
                Download Resume
            </a>
        </div>
        <div class="contactsimagecontainer">
        <a href="https://www.instagram.com/dmorris13"><img className="clickablelogo" src={require('./assets/instalogo.png')}/></a>
        <a class="logo" href="https://www.linkedin.com/in/danielmorris96/"><img className="clickablelogo" src={require('./assets/linkedin.png')}/></a>
        <a class="logo" href="https://github.com/kxdan"><img className="clickablelogo" src={require('./assets/github.png')}/></a>
        <a class="logo" href="mailto:dmorriswebsite@outlook.com"><img className="clickablelogo" src={require('./assets/mailicon.png')}/></a>
        </div>
    </section>
    );
  }

  export default ContactsSection;