import React, { useEffect } from 'react';
import './styles/DarkTheme.css';

import './styles/App.css';
import Typewriter from './Typewriter';
import JourneySection from './JourneySection'
import ContactsSection from './ContactsSection';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from "history";


//Enable app insights only if we have the key set.
//TODO: Enable this only in PROD, there's gotta be a way? use NODE_ENV environmental variable, have to find a way to set it in docker, and validate it works
if (process.env.REACT_APP_CONNECTION_STRING) {
  const browserHistory = createBrowserHistory({ basename: '' });
  var reactPlugin = new ReactPlugin();
  var appInsights = new ApplicationInsights({
      config: {
          connectionString: process.env.REACT_APP_CONNECTION_STRING,
          extensions: [reactPlugin],
          extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory }
          }
      }
  });
  appInsights.loadAppInsights();
}

function App() {
  //TODO: remember the scroll types, sticky scroll will be needed for the burger at top right
  //Smooth scroll will be needed when we select something in that burger to take us to the correct page
  //Parralax scroll will be used for that overlapping effect you want over the images on the different pages
  //TODO: Make all the text on the bottom appear on scroll
  //TODO: to show image as last on parallax scroll       {//This is just so the image is shown again at the end <section id="end">      <div className="backgroundimage"><Typewriter /></div></section>}
  //TODO: fix the scroll, when the box gets too big the appear on scroll thing waits until you scrolled the entire way
  //TODO: the scroll starts removing stuff when it's only partially off the screen
  //TODO: when the parent isn't visible, child shouldn't be either. but not the other way around
  
  return (
    <div id="mainAppDiv">
    <div className="App">
      
      <section id="introSection">
      <div className="backgroundimage">             
          <Typewriter />
      </div>
      </section>

      <JourneySection />

      <ContactsSection/>

    </div>
    </div>
  );
}

export default withAITracking(reactPlugin, App);