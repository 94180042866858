import './styles/ResumeSectionDesktop.css';
import './styles/ResumeSectionMobile.css';
import UnitedStatesResume from './UnitedStatesResume';
import NorwayResume from './NorwayResume';
import UnitedKingdomResume from './UnitedKingdomResume';

function ResumeSection(props) {
  const currentSlide = props.index;

  return (
    <div id="resumesection">
      {currentSlide === 0 && <UnitedKingdomResume/>}
      {currentSlide === 1 && <NorwayResume/>}
      {currentSlide === 2 && <UnitedStatesResume/>}
    </div>
  );
}

export default ResumeSection;