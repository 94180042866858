import React, { useEffect, useRef, useState } from 'react';
import './styles/AnimatedDiv.css';
import Carousel from './Carousel.jsx';
import ImageGrid from './ImageGrid';
import ResumeSection from './ResumeSection';

function JourneySectionCarousel() {
    const sliderRef = useRef(null);
    const [currentSlide, setCurrentSlide] = useState(2);

    useEffect(() => {
      const textBox = document.querySelector('#journeysectioncarousel.animateddiv');
  
      function animateTextBox() {
        const position = textBox.getBoundingClientRect();
  
        const viewActivationOffset = 175;

        // Check if the textbox is in view
        // Want it to appear when it's partially in view, so add a bit to what the window can see
        if (position.top >= 0 && position.bottom <= window.innerHeight + viewActivationOffset) {
            textBox.classList.add('active');
            textBox.classList.remove('hidden');
        } 
      }
  
      document.addEventListener('scroll', animateTextBox);
      return () => {
        document.removeEventListener('scroll', animateTextBox);
      };
    }, []);

    const handleSlideChange = (index) => {
      setCurrentSlide(index);
      // Call the callback function to update something in another component
      updateOtherComponent(index);
    };
  
    const updateOtherComponent = (index) => {
      // Do something to update another component with the new slide index
      console.log(`New slide index: ${index}`);
    };

    //idk if resume section should go here or not (the component)
    //we could potentially handle the switching of what's visible etc here
    //doesn't make sense, slider needs to handle itself, it's already built in
    return (
        <div>
        <div id="journeysectioncarousel" className="animateddiv">
            <Carousel initialSlide={currentSlide} afterChange={handleSlideChange} refs={{sliderRef: sliderRef}}></Carousel>
            <div className="middleContainer">
                <ImageGrid refs={{sliderRef: sliderRef}}/>
            </div>
        </div>

        <ResumeSection index={currentSlide}/>
        </div>

    );
}

export default JourneySectionCarousel;